import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Jerk 5-5-5-5\\@70% 1RM`}</p>
    <p>{`&`}</p>
    <p>{`Band Face Pulls 10-10-10-10`}</p>
    <p>{`then,`}</p>
    <p>{`12:00 AMRAP of:`}</p>
    <p>{`20-S2OH (75/55)`}</p>
    <p>{`10-T2B`}</p>
    <p>{`20-Double Unders`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit Kids today at 9:30am at East.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Judging for workout 17.1 of the CrossFit Open will continue today
from 10-12 at The Ville and 12-1:30 at East. If you are not able to get
in today we will judge on Monday (rest day) for unlimited members.  If
you are an open gym member and cannot come in today at either location
please contact Daniel to set up a time on Monday.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      